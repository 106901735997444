<!-- Footer Section -->
<footer>
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <h3>About Vitartha</h3>
        <p>One of the key concept of Hinduism is Puruṣārtha. It literally means "object of human pursuit" and
          points to the four main goal of human life... <a href="about.html" routerLink="/about" class="readmore">Read more...</a>
        </p>
      </div>
      <div *ngFor="let row of footertitle" class="col-md-2">
        <h2>{{ row.title }}</h2>
        <ul>
          <li *ngFor="let item of row.list"><i [ngClass]="item.class"></i><a routerLink="{{ item.href }}">{{ item.link1 }}</a></li>
        </ul>
      </div>
      <div class="col-md-4">
        <h2>Social Connect</h2>
        <div class="social">
          <ul>
            <li *ngFor="let link of links"><a href="{{ link.href }}" target="_blank"><i [ngClass]="link.class"></i></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer-bottom Section -->
  <div class="footer-bottom">
    <div class="container">
      <p>&copy; Vitartha LLP - 2017</p>
    </div>
  </div>
</footer>


