import {BrowserModule} from '@angular/platform-browser';
import {NgModule, PLATFORM_ID, APP_ID, Inject} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './application/app.component';

import {HomeComponent, SafePipe} from './home/home.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {AboutComponent} from './about/about.component';
import {ContactComponent} from './contact/contact.component';
import {AboutHeaderComponent} from './about-header/about-header.component';
import {TermsPolicyComponent} from './terms-policy/terms-policy.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {ContactService} from './services/contact.service';
import {environment} from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    AboutComponent,
    ContactComponent,
    SafePipe,
    AboutHeaderComponent,
    TermsPolicyComponent,
    FooterComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [ContactService],
  bootstrap: [AppComponent]
})

export class AppModule {
   constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
  //  console.log(`Running ${platform} with appId=${appId}`);
  }
}
