<!-- About & Contact Header -->
<app-about-header xmlns:this.form_success></app-about-header>

<!-- Contact Us Section -->
<div class="con-hero">
  <div class="container text-center">
    <div class="row justify-content-center">
      <div class="col-md-8 pt-2 mt-5">
        <h1 class="mb-2">Contact us</h1>
      </div>
    </div>
  </div>
</div>

<!-- Contact content section -->
<div class="call-text ft-mb">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <ul class="get_list">
          <li *ngFor="let data of detail"><i [ngClass]="data.class"></i>
            <div>
              <span>
              <h5>{{ data.title }}</h5>
              <p>{{ data.text }}<br>{{ data.text1 }}</p>
              </span>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-md-6">
        <div class="card enq">
          <div class="card-block">
            <h5>Get in Touch</h5>
            <div id="form-messages"></div>
            <form novalidate name="contact" #formgroup="ngForm" method="post" *ngIf="showHide">
              <div id="contact-form">
                <div class="row">
                  <div *ngFor="let forms of form_obj;" class="col-md-6">
                    <div class="form-group has-error">
                      <label>{{ forms.label }}</label>
                      <input type="text" id={{forms.id}} class="form-control" [(ngModel)]=x[forms.key]  name="{{forms.name}}" required>
                      <small>{{ forms.text }}</small>
                    </div>
                  </div>
                </div>

                <div class="form-group has-error">
                  <label for="contact-email">Email ID</label>
                  <input  type="text" id="contact-email" class="form-control" [(ngModel)]=x.emailid name="email" required pattern="^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$">
                  <small>[ Enter valid email id ]</small>
                </div>

                <div class="form-group">
                  <label for="exampleTextarea">Your Query</label>
                  <textarea class="form-control" id="exampleTextarea" rows="3" [(ngModel)]="x.desc" name="description"></textarea>
                </div>
                <button type="button" id="contact-submit" class="btn btn-green" [disabled]="formgroup.invalid" (click)="onSubmit()">Submit</button>
              </div>
            </form>

            <!-- Events which occur after clicking on Submit Button -->
            <div *ngIf="!showHide && !showHideDiv" id="contact-loading"><img src="assets/images/loading.gif" alt="Loading"/><br>
              Email Sending...
            </div>
            <div *ngIf="showDiv && !hidediv" id="contact-success"> <p>Your message has been sent sucessfully to our team and they will get in touch with you
              asap.</p>
            </div>
            <div *ngIf="showErrorDiv && !hidediv" id="contact-failed">Error!!! Message sending failed, try after sometime.</div>
          </div>
        </div>
        </div>
    </div>
  </div>
</div>
