<!-- About & Contact Header  -->
<app-about-header></app-about-header>

<!-- About Vitartha Section -->
<div class="abt-hero">
  <div class="container text-center">
    <div class="row justify-content-center">
      <div class="col-md-8 pt-5 mt-5">
        <h1 class="mb-4">About Vitartha</h1>
        <p>Vitartha is a Sanskrit word made from 'sandhi' of two words: 'Vitt' and 'Artha'</p>
      </div>
    </div>
  </div>
</div>

<!-- About Content Sector -->
<div class="abt-text">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <img src="../assets/images/edu_img.jpg" class="img-fluid" alt="Education">
      </div>
      <div class="col-md-6">
        <h4>Freedom Achieved</h4>
        <h2>Why?</h2>
        <p>Even after 70 years of Independence, India is far from the achieving the freedom that it should have
          achieved long ago. Today, with the population of India being young, is the perfect opportunity to
          achieve the freedom that we aspire to achieve. The time is even more perfect. With the current
          Government of India, led by a leader with such a strong vision, Shri Narendra Modi, focusing on
          digitization initiatives like Pradhan Mantri Jan Dhan Yojna, GST and strong unprecedented moves like
          Demonetization, the current economy of India is becoming simpler and streamlined. It is moving
          towards a cashless future. To change this rare opportunity into a hope and then a dream come true
          moment for India, Vitartha LLP came into existence in 2017, after a long 3 years of pre-journey of
          struggle and experience with a strong motto "Swantratam Praptavan" or "Freedom Achieved". </p>
        <p class="t_head">How?</p>
        <p>Vitartha is a FinTech Company. The long term goal of Vitartha is to make Indian Citizen a Financially
          Independent Citizen of the country and hence, bringing a huge change in the economy of India. We
          also realize the fact that the term Financial Independence has a different definition for everyone.
          On a more generalized note, Vitartha, as the name suggests, is focused on helping humans to achieve
          goal of human life by providing certain assistance in means of life or the activity and resources
          that help one to achieve goal of life. </p>
      </div>
    </div>
  </div>
</div>

<!-- About Service Section -->
<div *ngFor="let service of abtservice">
  <div [ngClass] = 'service.class'>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10 text-center">
        <div class="abt_tittle">
          <h4>{{ service.title1 }}</h4>
          <h2>{{ service.title2 }}</h2>
        </div>
        <p>{{ service.description }}</p>
      </div>
    </div>
  </div>
</div>
</div>
