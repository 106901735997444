import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs';

export class Contact {
  constructor(public first: string, public last: string, public phoneNumber: number, public email_id: string, public description: string) {
  }
}

interface Resp {
  success: boolean;
  result: string;
}

@Injectable()
export class ContactService {

  data: any;
  rootURL: string;

  constructor(private http: HttpClient) {

  }

  update(data_obj: Contact) {

    this.data = {
      callback: 'JSON_CALLBACK',
      lastname: data_obj.last,
      firstname: data_obj.first,
      email: data_obj.email_id,
      mobile: data_obj.phoneNumber,
      description: data_obj.description
    };
  }

  getData(data_obj: Contact) {
    this.rootURL = 'https://www.civilmachines.com/crm/modules/Webforms/capture.php?callback=JSON_CALLBACK&lastname=' + this.data.lastname + '&firstname=' + this.data.firstname + '&email=' + this.data.email + '&mobile=' + this.data.mobile + '&description=' + this.data.description + '&website=http://www.vitartha.com/contact.html&leadsource=%22Vitartha%22&name=%22vitartha-enquiry%22&publicid=6bceba4a1c03e48daf27e0b96d5ade4b&callback';
    // const resp_data: Resp = {success: true, result: 'ok'};
    return this.http.jsonp<Resp>(this.rootURL, 'callback');
  }
}

