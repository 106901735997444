import {Component, OnInit} from '@angular/core';
import * as sections from 'assets/shared/sections.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() {
  }

  /* Footer title -- Used in Footer Component */
  footertitle = sections['footer'];

  /* Footer links -- Used in Footer Component */
  links = sections['social'];

  ngOnInit() {

  }

}
