import { Component, OnInit } from '@angular/core';
import * as sections from 'assets/shared/sections.json';
import {Contact, ContactService} from '../services/contact.service';
import { FormGroup } from '@angular/forms';
import {fail} from 'assert';
import {tryParse} from 'selenium-webdriver/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css', '../../assets/css/styles.scss']
})


export class ContactComponent implements OnInit {

  desc: string;
  showHide: boolean;
  showDiv: boolean;
  showHideDiv: boolean;
  hidediv: boolean;
  showErrorDiv: boolean;
  form_success: boolean;

  constructor(private contact: ContactService) {
    this.showHide = true;

  }

  /* associative array to store contact form input-value in key-value pair */
  x = {};

  /* Details of Contact Page */
  detail = sections['detail'];

  /* TextBoxes of Contact Page -- Form */
  form_obj = sections['form'];

  ngOnInit() {

  }

  /* Function for sending data to CRM */
  onSubmit() {

    const form_Serv = new Contact(this.x['fname'], this.x['fname'], this.x['phone'], this.x['emailid'], this.x['desc']);
    this.contact.update(form_Serv);
    // console.log(this.x);
    // console.log(this.x['emailid']);
    /*   this.form_success = true;
       console.log(this.form_success);*/


    this.contact.getData(form_Serv).subscribe(
      data => {
        // console.log(data);
        // console.log(data.success);
        this.form_success = data.success;
        console.log(this.form_success);
      }
    );
    this.changeShowStatus();
  }

  /* Function for Form Loader, failed and success message */
  changeShowStatus() {
    this.showHide = !this.showHide;
      setInterval(() => {
        if (this.form_success) {
          this.showDiv = true;
        } else {
            this.showErrorDiv = true; }
        this.showHideDiv = true;
      }, 2000);
    }
}



