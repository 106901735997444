<!-- Header of the page -->
<nav class="navbar fixed-top navbar-toggleable-md scrolling-navbar double-nav">
  <!--<button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
          data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>-->
  <a class="navbar-brand" href="/" routerLink="/">
    <img src="assets/images/vitartha2.png" alt="Vitartha"/>
    <img src="assets/images/vitartha2.png" alt="Vitartha"/>
  </a>
  <div class="collapse navbar-collapse " id="navbarsExampleDefault">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item active">
        <a class="nav-link" href="tel:+911204545647"> <i class="fa fa-phone"></i> +91 120 4545647 </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/about">About</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/contact" >Contact</a>
      </li>
    </ul>
  </div>
</nav>
