import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AboutComponent} from './about/about.component';
import {ContactComponent} from './contact/contact.component';
import {TermsPolicyComponent} from './terms-policy/terms-policy.component';

const routes: Routes = [
  {path: '', component: HomeComponent, data: {logo: true}, },
  {path: 'about', component: AboutComponent, data: {logo: false}, },
  {path: 'contact', component: ContactComponent, data: {logo: false}, },
  {path: 'terms-policy', component: TermsPolicyComponent, data: {logo: false}, },
  {path: 'terms-policy', component: TermsPolicyComponent, data: {logo: false}, },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
