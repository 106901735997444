<!-- Header Section -->
<app-header></app-header>


<!-- Feedback Survey Form Button -->
<!--<div id="feedback">
  <a data-toggle="modal" data-target="#myModal" class="pull_feedback"><img src="/assets/images/talk.png" alt="Talk to us"> Talk to us </a>
</div>-->

<!-- Heading Section -->
<div class="jumbotron">
  <div class="container text-center">
    <div class="row justify-content-center">
      <div class="col-md-10 pt-5 mt-5">
        <h1 class="display-3 mb-4">Empowering You</h1>
        <p>Freedom is about gaining knowledge to exercise the choices. At Vitartha, we believe in empowering you
          in making your own financial decisions. Our ecosystem of programs and tools offers you the knowhow
          and choices to really become financially independent. </p>
      </div>
    </div>
  </div>
</div>

<!-- Services Section-->
<div class="service">
  <div class="container-fluid">
    <div class="row">
      <div *ngFor="let subs of sections; even as even; odd as odd" class="col-md-6"
           [ngClass]="{'advisor':even, 'advisory':odd}">
        <div class="hexagon">
          <i class="fa" [ngClass]="{'fa-universal-access':even, 'fa-user-circle-o':odd}"></i>
        </div>
        <h2>{{ subs.title }}</h2>
        <p>{{ subs.description }}</p>
        <div class="gap"></div>
      </div>
    </div>
  </div>
</div>

<!-- App Section-->
<div class="service1"> 
  <div class="container-fluid">
    <div>
      <h2 style="text-align: center; font-size: 2.5rem;padding: 30px;color: white; font-weight: bold;">Do Smart Investments With</h2>
  </div>
    <div class="row">
      <div *ngFor="let subs of sections; even as even; odd as odd" class="col-md-6"
           [ngClass]="{'advisor1':even, 'advisory1':odd}">
           <div class="mb-3">
            <img src="{{subs.src}}" class="img-responsive" width="100px" height="100px">
           </div>
        <h2>{{ subs.title1 }}</h2>
        <p>{{ subs.description1 }}</p>
        <div class="gap"></div>
        <a href="{{ subs.link }}" target="_blank"><img src="./assets/images/googleplay.png" class="responsive" style="width: 200px; height: 78px; padding: 15px;"></a>
        <a href="{{ subs.link1 }}" target="_blank"><img src="./assets/images/app-img.png" class="responsive" style="width: 200px; height: 78px; padding: 15px;"></a>
        <!-- <a class="btn" [ngClass]="{'btn-brown':even, 'btn-green':odd}">View more</a> -->
      </div>
    </div>
  </div>
</div>

<!-- Education Section -->
<div class="edu">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <img src="/assets/images/edu_img.jpg" class="img-fluid" alt="Education">
      </div>
      <div class="col-md-6 wow fadeIn" data-wow-duration="1s" data-wow-delay="0s">
        <h2>Education</h2>
        <p>Our flagship education programs help you gain path-breaking insight on making financial decisions using
          simple but effective rules. We intend to equip you with necessary tools to control your own finance. </p>
        <p class="t_head">Our flagship programs:</p>
        <p>The <strong>'THREE STEPS'</strong> to financial freedom' program empowers you with time-tested strategies to
          become your own financial advisor. You will be able to make right personal financial decisions in any
          situation. </p>
      </div>
    </div>
  </div>
</div>

<!-- Registration for Courses Section -->
<div class="cources">
  <div class="container">
    <div class="head_tittle">
      <h2>Become Your Own Advisor</h2>
    </div>
    <div class="row cources_box">
      <div class="col-md-4 cources_col">
        <div class="text-center">
          <h3 style="font-weight:bolder">A Tryst with Money</h3>
          <p style="color: white; margin-top: 20px;">A Tryst with Money is an educative endeavor demonstrated with factual and fictional events of our journey with money which, according to author, is leading us to an unknown destination. Unfortunately, the human race seems happy to tread along. Acclaimed financial expert and author Samar Vijay tracks historical events instrumental in transforming our society into a power-centric one..</p>
          <img src="./assets/images/tryst.jpg" class="img-responsive" style="width: 145px;height: 165px;margin-bottom: 20px;">
          <a href="https://www.amazon.com/Tryst-Money-account-journey-destiny-ebook/dp/B00NXMOFWQ/ref=sr_1_1?dchild=1&keywords=Samar+Vijay+A+Tryst+with+Money&qid=1599543442&sr=8-1" target="_blank" class="btn btn-green"><i class="fa fa-amazon" aria-hidden="true" style="font-size: 20px; vertical-align: middle;"></i>Get On Amazon</a> 
        </div>
      </div>
      <div class="col-md-4 cources_col">
        <div class="text-center">
          <h3 style="font-weight:bolder">In Control of Money</h3>
          <p style="color: white; margin: 20px 0px 30px 0px;">Our financial world is getting more complex but also offers numerous opportunities that were previously unheard of. To benefit from new opportunities, we need to become a bit wiser than before. This book offers a two-point strategy that everyone can use to make decisions related to money, without getting into the complexities of financial world. One can be up and running like a pro.</p>
          <img src="./assets/images/money.jpg" class="img-responsive" style="width: 145px;height: 165px;margin-bottom: 20px;">
          <a href="https://www.amazon.in/-/en/Samar-Vijay-ebook/dp/B081MXFNZT" target="_blank" class="btn btn-green"><i class="fa fa-amazon" aria-hidden="true" style="font-size: 20px; vertical-align: middle;"></i>Get On Amazon</a>
        </div>
      </div>
      <div class="col-md-4 register_form">
        <div id="contact-form">
          <div class="card-block">
            <div class="book-card" style="text-align: center;">
            <h4>Get our free E-Book</h4><br>
            <!-- <div id="form-messages"></div> -->
            <!-- <form novalidate name="register" method="post" #formvalid="ngForm" *ngIf="showHide"> -->
              <div id="register-form">
                <!-- <div class="row">
                  <div *ngFor="let forms of form;" class="col-md-6">
                    <div class="form-group error">
                      <label>{{ forms.label }}</label>
                      <input type="text" id="{{forms.id}}" class="form-control" [(ngModel)]=y[forms.key] name="{{forms.name}}" required>
                      <small>{{ forms.text }}</small>
                    </div> 
                  </div>
                </div>

                <div class="form-group error">
                  <label for="contact-email">Email ID</label>
                  <input type="text" id="contact-email" class="form-control has-error" [(ngModel)]="y.emailid" name="email" required pattern="^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$">
                  <small>[ Enter valid email id ]</small>
                </div> -->

                <div>
                  <p style="text-align: center;">Fill a form to receive the E-book "In Control Of Money" for FREE !</p>
               </div>

                <div style="text-align: center;"><a href="https://docs.google.com/forms/d/e/1FAIpQLSd5p2gqOsG_cLSmVKV1RIatrsOzI6aHIOzKjqNUJ1qCg5LZvg/viewform?usp=sf_link" target="_blank" id="contact-submit" class="btn btn-green">Fill The Form</a>
              </div>
              </div>
            <!-- </form> -->


            <!--After submitting form-->
            <div *ngIf="!showHide && !showHideDiv" id="contact-loading"><img src="assets/images/loading.gif" alt="Loading"/><br>
              Email Sending...
            </div>
            <div *ngIf="showDiv && !hidediv" id="contact-success"> Your message has been sent sucessfully to our team and they will get in
              touch with you asap.
            </div>
            <div *ngIf="showErrorDiv && !hidediv" id="contact-failed"> Error!!! Message sending failed, try after sometime.</div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Udemy Courses Section --> 
<div class="cources">
  <div class="container">
    <div class="head_tittle1">
      <h2>Learn From The Best Udemy Courses</h2>
    </div>
    <div class="row cources_box">
      <div class="col-md-6 cources_col1" style=" background-image: url(./assets/images/course1-bg.jpeg); background-size: cover; padding: 2rem; position: relative;">
        <div class="text-center">
          <h3>World Economy - The Three Years after Corona</h3>
          <p class="special">What you will learn!</p>
          <ul>
            <li *ngFor="let list of condition"><i class="fa fa-long-arrow-right"></i>{{ list.data }}</li>
          </ul>
          <a href="https://www.udemy.com/course/world-economy-the-three-years-after-corona/" target="_blank" class="btn btn-brown">Enroll For Free!</a>
        </div>
      </div>
      <div class="col-md-6 cources_col1" style=" background-image: url(./assets/images/course2-bg.jpeg); background-size: cover; padding: 2rem; position: relative;">
        <div class="text-center">
          <h3>Profit from Swing Trade</h3> 
          <p class="special pt-3">What you will learn!</p>
          <ul>
            <li *ngFor="let list of condition"><i class="fa fa-long-arrow-right"></i>{{ list.data1 }}</li>
          </ul>
          <a href="https://www.udemy.com/course/profit-from-swing-trade/" target="_blank" class="btn btn-brown">Enroll Now!</a>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Live Events Section -->
<div class="event ft-mb">
  <div class="container">
    <div class="head_tittle">
      <h2>Check Out Our Advisor Videos</h2>
      <p>Country’s economy has had a major set back due Corona Pandemic. What would be the scenario after three years? Check out the videos to find out!</p>
    </div>
    <div class="row">
      <div *ngFor="let video of videos;" class="col-md-4">
        <div style="position:relative;height:0;padding-bottom:56.21%">
          <!-- Pipe used below is declared in home.component.ts -->
          <iframe [src]="video.link|safe" style="position:absolute;width:100%;height:100%;left:0;padding: 5px;" width="200"
                  height="200" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Survey Modal 1 (Basic Intro details) -->
<div class="modal fullpage fade in" id="myModal" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-dialog ng-scope">
        <div class="modal-content2">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title ng-binding" style="float: left">Survey Form</h4>
          </div>
          <div class="modal-body">
            <div class="otp_verify ng-hide" aria-hidden="true">
              <div class="col-md-6 center-block">
              </div>
            </div>
            <div class="row">
              <form role="form">

                <div class="form-group">
                  <input type="text" class="form-control" id="inputName" placeholder="Name" required/>
                </div>
                <div class="form-group space">
                  <input type="text" class="form-control" id="inputMobile" placeholder="Mobile" required />
                </div>

                <div class="form-group space">
                  <input type="text" class="form-control" id="inputEmail" placeholder="Email-ID" required pattern="^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$"/>
                </div>
                <div class="form-group space">
                  <p>D.O.B</p>
                  <input type="date" class="form-control" id="inputDOB" placeholder="D.O.B" required/>
                </div>
                <h6 style="margin-left: 130px">Are you currently studying?</h6>

                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="studyStatus" id="YesButton">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio" id="NoButton" name="studyStatus">No
                  </div>

                  <button class="btn btn-lg  btn-block btn-submit" style="width: 40%; margin-top: 80px" aria-disabled="false" data-toggle="modal" data-target="#Modal2" data-dismiss="modal">Next</button>

                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Modal 2 (employee details)-->
<div class="modal fullpage fade in" id="Modal2" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-dialog ng-scope">
        <div class="modal-content2">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title ng-binding" style="float: left">Survey Form</h4>
          </div>
          <div class="modal-body">
            <div class="otp_verify ng-hide" aria-hidden="true">
              <div class="col-md-6 center-block"></div>
            </div>
            <div class="row">
              <form role="form">

                <div class="form-group">
                  <h6>Are you currently working?</h6>
                </div>

                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="workingStatus"  id="YesworkingID">Yes
                  </div>

                  <div class="radioBlock">
                    <input type="radio" id="NoButton1" name="workingStatus">No
                  </div>
                </div>

                <div id="InvestBlock">

                  <div class="form-group space">
                    <input  type="text" class="form-control hide" id="inputCompanyName" placeholder="Company Name"/>
                  </div>

                  <div class="form-group space">
                    <input type="text" class="form-control hide" id="inputPosition"  placeholder="Position"/>
                  </div>

                  <div class="form-group space">
                    <input type="text" class="form-control hide" id="inputSalary"  placeholder="Estimated Annual Salary"/>
                  </div>

                </div>

                <div class="radioOptions radioBlock">
                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 80px; width: 40%" data-toggle="modal" data-target="#myModal" data-dismiss="modal">Back</button>
                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 80px; width: 40%" data-toggle="modal" data-target="#Modal3" data-dismiss="modal" >Next</button>
                </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


<!--Modal 3 (Bank questions)-->
<div class="modal fullpage fade in" id="Modal3" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-dialog ng-scope">
        <div class="modal-content2">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title ng-binding" style="float: left">Survey Form</h4>
          </div>
          <div class="modal-body">
            <div class="otp_verify ng-hide" aria-hidden="true">
              <div class="col-md-6 center-block">
              </div>
            </div>
            <div class="row">
              <form role="form">

                <div class="form-group">
                  <h6>Do you have Personal Bank account?</h6>
                </div>

                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="personalAC" id="YesButton2">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio" id="NoButton2" name="personalAC">No
                  </div>
                </div>


                <div class="form-group ">
                  <h6>Do you know how to write a cheque?</h6>
                </div>

                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="Cheque" id="YesButtonCheque">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio" id="NoButtonCheque" name="Cheque">No
                  </div>
                </div>

                <div class="form-group ">
                  <h6>Which of the following is/are right way to write a A/C Payee cheque?</h6>
                </div>


                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="Payee" id="YesButtonPayee">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio" id="NoButtonPayee" name="Payee">No
                  </div>
                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 80px; width: 40%" data-toggle="modal" data-target="#Modal2" data-dismiss="modal">Back</button>

                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 80px; width: 40%" data-toggle="modal" data-target="#Modal4" data-dismiss="modal" >Next</button>

                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Modal 4 (DD details)-->
<div class="modal fullpage fade in" id="Modal4" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-dialog ng-scope">
        <div class="modal-content2">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title ng-binding" style="float: left">Survey Form</h4>
          </div>
          <div class="modal-body">
            <div class="otp_verify ng-hide" aria-hidden="true">
              <div class="col-md-6 center-block">
              </div>
            </div>
            <div class="row">
              <form role="form">

                <div class="form-group">
                  <h6>Do you know what is DD?</h6>
                </div>

                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="DD" id="yesDD">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio" id="NoDD" name="DD">No
                  </div>
                </div>


                <div class="form-group ">
                  <h6>Do you know what is Banker's cheque?</h6>
                </div>

                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="BankerCheque" id="YesBanker">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio" id="NoBanker" name="BankerCheque">No
                  </div>
                </div>

                <div class="form-group ">
                  <h6>Are DD & Banker's cheque same</h6>
                </div>


                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="Same" id="YesSame">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio" id="NoSame" name="Same">No
                  </div>

                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 80px; width: 40%" data-toggle="modal" data-target="#Modal3" data-dismiss="modal">Back</button>

                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 80px; width: 40%" aria-disabled="false" data-toggle="modal" data-target="#Modal5" data-dismiss="modal">Next</button>

                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Modal 5 (BHIM App related questions)-->
<div class="modal fullpage fade in" id="Modal5" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-dialog ng-scope">
        <div class="modal-content2">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title ng-binding" style="float: left">Survey Form</h4>
          </div>
          <div class="modal-body">
            <div class="otp_verify ng-hide" aria-hidden="true">
              <div class="col-md-6 center-block">
              </div>
            </div>
            <div class="row">
              <form role="form">

                <div class="form-group">
                  <h6>Have you heard about BHIM App?</h6>
                </div>

                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="BHIM" id="yesBhim">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio"  name="BHIM" id="NoBhim">No
                  </div>
                </div>


                <div class="form-group ">
                  <h6>Have you ever used UPI?</h6>
                </div>

                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="UPI" id="YesUPI">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio"  name="UPI" id="NoUPI">No
                  </div>
                </div>

                <div class="form-group ">
                  <h6>Are NEFT & IMPS same?</h6>
                </div>


                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="IMPSSame" id="YesIMPS">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio" name="IMPSSame" id="NoIMPS" >No
                  </div>

                </div>

                <div class="form-group ">
                  <h6>Are NEFT & RTGS same?</h6>
                </div>


                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="NeftSame" id="YesNeft">Yes
                  </div>
                  <div class="radioBlock">
                    <input type="radio" name="NeftSame" id="NoNeft" >No
                  </div>

                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 0px; width: 40%" data-toggle="modal" data-target="#Modal4" data-dismiss="modal">Back</button>

                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 0px; width: 40%" aria-disabled="false" data-toggle="modal" data-target="#Modal6" data-dismiss="modal" >Next</button>

                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Modal 6 (Investment Related Questions)-->
<div class="modal fullpage fade in" id="Modal6" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-dialog ng-scope">
        <div class="modal-content2">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title ng-binding" style="float: left">Survey Form</h4>
          </div>
          <div class="modal-body">
            <div class="otp_verify ng-hide" aria-hidden="true">
              <div class="col-md-6 center-block"></div>
            </div>
            <div class="row">
              <form role="form">

                <div class="form-group">
                  <h6>Do you Invest Money?</h6>
                </div>

                <div class="radioOptions">
                  <div class="radioBlock" >
                    <input  type="radio" name="InvestStatus" id="YesInvest">Yes
                  </div>

                  <div class="radioBlock">
                    <input type="radio" id="NoInvest" name="InvestStatus">No
                  </div>
                </div>

                <div class="form-group" id="investID" >
                  <input type="text" class="form-control hide" id="InvestmentName" placeholder="Where do you Invest?"/>
                </div>

                <div class="form-group">
                  <h6>Do you have any Insurance?</h6>
                </div>

                <div class="radioOptions">
                  <div class="radioBlock">
                    <input  type="radio" name="InsuranceStatus" id="YesInsurance">Yes
                  </div>

                  <div class="radioBlock">
                    <input type="radio" id="NoInsurance" name="InsuranceStatus">No
                  </div>

                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 130px; width: 40%" data-toggle="modal" data-target="#Modal5" data-dismiss="modal">Back</button>

                  <button class="btn btn-lg  btn-block btn-submit" style="margin-top: 130px; width: 40%" data-toggle="modal" data-target="#Modal7" data-dismiss="modal">Submit</button>
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--Modal 7 (Thanking Note)-->
<div class="modal fullpage fade in" id="Modal7" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-dialog ng-scope">
        <div class="modal-content2">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title ng-binding" style="float: left">Survey Form</h4>
          </div>
          <div class="modal-body">
            <div class="otp_verify ng-hide" aria-hidden="true">
              <div class="col-md-6 center-block"></div>
            </div>
            <div class="row">
              <form role="form">

                <div class="form-group">
                  <h4 style="margin-left: 100px;margin-top: 40px">Thank you!!!</h4>
                </div>

                <h6 style="margin-left: 80px; margin-top: 50px">Click Here to get 25% discount on "A Tryst with Money"</h6>

              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


