import { Component, OnInit } from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  routeData = null;
  logo = true;
  constructor(private route: Router) { }

  ngOnInit() {
    this.routeData = this.route.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {

        this.logo = data.state.root.firstChild.data.logo;
        // console.log( this.logo);
      }
    });
  }

}
