import {Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import * as sections from 'assets/shared/sections.json';
import { Contact, ContactService } from '../services/contact.service';
import {FormGroup} from '@angular/forms';

/*Pipe -- Used in Events for displaying Videos*/
@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  showHide: boolean;
  showDiv: boolean;
  showHideDiv: boolean;
  hidediv: boolean;
  showErrorDiv: boolean;
  form_success: boolean;

  constructor(private contact: ContactService) {
    this.showHide = true;
  }

  y = {};

  /* Information of Advisor & Advsory -- Used in Home Component */
  sections = sections['advisor'];

  /* /*Links for Videos -- Used in Events*/
  videos = sections['videos'];

  /* TextBoxes of Home Page -- Form */
  form = sections['form'];

  /* Conditions of Home Page Form */
  condition = sections['condition'];

  ngOnInit() {

  }

  onSubmit() {
    const form_Serv = new Contact(this.y['fname'], this.y['fname'], this.y['phone'], this.y['emailid'], this.y['desc']);
    this.contact.update(form_Serv);

    this.contact.getData(form_Serv).subscribe(
      data => {
        // console.log(data);
        this.form_success = data.success;
      }
    );
    this.changeShowStatus();
  }
  /* Function for Registeration & Contact us Form Loader and success message */
  changeShowStatus() {
    this.showHide = !this.showHide;
    setInterval(() => {
      if (this.form_success) {
        this.showDiv = true;
      } else {
        this.showErrorDiv = true;
      }
      this.showHideDiv = true;
    }, 2000);
  }
}


